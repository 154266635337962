<template>
  <NCard
    size="small"
    :bordered="false"
    :segmented="{ content: 'hard' }"
    :title="isEdit ? '编辑试题' : '新增试题'" 
  >
    <NForm
      ref="formRef"
      label-placement="left"
      :label-width="useFor === 'forData' ? 95 : 120"
      require-mark-placement="left"
      :model="formValue"
      :rules="formRules"
    >
      <NFormItem label="题型：" path="type">
        <div style="width: 550px;">
          <NSelect
            :options="typeOptions"
            v-model:value="formValue.type"
            @update:value="handleQuesTypeChange"
          />
        </div>
      </NFormItem>
      <NFormItem label="习题题干：" path="title">
        <NInput
          style="width: 550px;"
          :autosize="{ minRows: 6, maxRows: 6 }"
          type="textarea"
          maxlength="2000"
          show-count
          clearable
          v-model:value="formValue.title"
        />
      </NFormItem>
      <NFormItem path="image_url" label="添加图片：" class="uploader-container" id="PracticalTraining-exercises">
        <div class="pics-box" :class="{ 'read-only' : formValue.id && useFor === 'forData'}">
          <!-- 头像上传 -->
          <UPheadPortrait text="限上传PNG、JPG、JPEG格式图片，大小不超过2M" 
            v-if="isOne"
            @imagedata="imagedata" 
            size="2" 
            removeUrl="/backend/web/upload/delete"
            :isExerciseBank="isTestPaper?isTestPaper:1"
            :imaData="imageURL"
          />
          <!-- 头像上传 -->
          <UPheadPortrait text="限上传PNG、JPG、JPEG格式图片，大小不超过2M"
            v-else
            @imagedata="imagedata" 
            size="2" 
            removeUrl="/backend/web/upload/delete" 
            :isExerciseBank="isTestPaper?isTestPaper:1"
            :imaData="imageURL"
          />
        </div>
      </NFormItem>
      <!-- <div style="height:35px"></div> -->
      <NDynamicInput
        v-if="formValue.type && Number(formValue.type) !== questionTypeEnum.OPEN"
        item-style="margin-bottom: 0;"
        :min="Number(formValue.type) === questionTypeEnum.FILL_BLANK ? 1 : 2"
        :max="Number(formValue.type) === questionTypeEnum.TRUE_FALSE ? 2 : 10"
        v-model:value="formValue.ques_select"
        #default="{ index, value }"
        @create="getDefaultQuesOption()"
      >
        <NFormItem
          :key="Date.now()"
          ignore-path-change
          :label="Number(formValue.type) === questionTypeEnum.FILL_BLANK ? `填空答案${index + 1}：` : `选项 ${ String.fromCharCode(65 + index) }：`"
          :path="`ques_select[${ index }].content`"
          :rule="formRules.ques_select"
        >
          <span
            v-show="Number(formValue.type) === questionTypeEnum.TRUE_FALSE"
          >{{ formValue.ques_select[index].content }}</span>
          <NInput
            v-show="Number(formValue.type) !== questionTypeEnum.TRUE_FALSE"
            style="width: 550px;"
            maxlength="200"
            show-count
            clearable
            v-model:value="formValue.ques_select[index].content"
          />
          <NButton
            v-show="Number(formValue.type) !== questionTypeEnum.FILL_BLANK"
            style="margin: 0 -10px 0 10px; font-size: 28px;"
            text
            @click="handleSelectAnswer(index)"
          >
            <NIcon :color="formValue.ques_select[index].isAnswer ? themeSettings.successColor : '#dedede'">
              <CheckmarkSharp />
            </NIcon>
          </NButton>
        </NFormItem>
      </NDynamicInput>
      <NFormItem label="习题解析：">
        <NInput
          style="width: 550px;"
          :autosize="{ minRows: 6, maxRows: 6 }"
          type="textarea"
          maxlength="2000"
          show-count
          clearable
          v-model:value="formValue.ques_analys"
        />
      </NFormItem>
      <NFormItem label="分值：" path="score">
        <NInputNumber
          style="width: 145px;"
          :min="0"
          :max="80"
          :step="0.5"
          v-model:value="formValue.score"
          @focus="handleScoreFocus"
          @blur="handleScoreBlur"
        />
      </NFormItem>
      <NFormItem v-if="useFor === 'toExercises'" label="标签：" path="label_ids">
        <ItemAddLabel
          ref="itemAddLabelRef"
          :label-type="resourceEnum.EXERCISES_RESOURCE_TYPE"
          @list-update="handleLabelChange"
        />
      </NFormItem>
    </NForm>
    <div style="margin-top: 20px;">
      <NSpace>
        <NButton @click="handleBack">返回</NButton>
        <NButton type="primary" @click="handleSave">保存</NButton>
      </NSpace>
    </div>
  </NCard>
</template>

<script setup>
  import { ref, reactive } from 'vue';
  import { useMessage } from 'naive-ui';
  import { CheckmarkSharp } from '@vicons/ionicons5'; 

  import ItemAddLabel from '@/components/ItemAddLabel/ItemAddLabel.vue';
  import UPheadPortrait from '@/components/UPheadPortrait/index.vue';
  import questionTypeMap, { questionTypeEnum } from '@/enumerators/question-type-map.js';
  import nonOperationa from '@/enumerators/non-operational.js';
  
  import themeSettings from '@/settings/theme-settings.js'; 
  import { resourceEnum } from '@/enumerators/resource-types-map.js';
  
  const message = useMessage();

  const props = defineProps({
    useFor: { // toExercises forData
      required: true,
      type: String
    },
    exerciseId: {
      type: String,
      default: null
    },
    isEdit: {
      required: true,
      type: Boolean
    },
    resReqParamsMap: {
      required: true,
      type: Object
    },
    isTestPaper:{
      type: Number,
      default: null,
    }
  });
  const emit = defineEmits(['back-click', 'save-click']);

  const imageURL = ref(
    [
    // {
    //   id: 'react',
    //   name: '我是react.png',
    //   status: 'finished',
    //   url: '07akioni.jpeg'
    // }
  ]
  )

  const typeOptions = Object.keys(nonOperationa).map(value => ({ label: nonOperationa[value], value }));
  
  const getDefaultQuesOption = (content = '', isAnswer = false) => ({ content, isAnswer });
  const getDefaultOtherTypeQuesOptions = () => [
    getDefaultQuesOption(),
    getDefaultQuesOption(),
    getDefaultQuesOption(),
    getDefaultQuesOption()
  ];
  const getDefaultTrueFalseQuesOptions = () => [
    getDefaultQuesOption('对'),
    getDefaultQuesOption('错')
  ];
  const getDefaultFillBlankTypeQuesOptions = () => [
    getDefaultQuesOption()
  ];
  let srcType = null;
  const getDefaultFormValue = () => {
    const tempFormValue = {};
    Object.keys(props.resReqParamsMap).forEach(key => {
      switch (key) {
        case 'type':
          tempFormValue[key] = null;
          srcType = null;
          break;
        case 'ques_select':
          tempFormValue[key] = getDefaultOtherTypeQuesOptions();
          break;
        case 'image_url':
          tempFormValue[key] = '';
          break;
        case 'label_ids':
          tempFormValue[key] = [];
          break;
        case 'ques_select_num':
          tempFormValue[key] = 0;
          break;
        case 'score':
          tempFormValue[key] = null;
          break;
        default:
          tempFormValue[key] = '';
      }
    });
    return tempFormValue;
  };
  const formRef = ref(null);
  const formValue = reactive(getDefaultFormValue());
  const formRules = {
    type: {
      required: true,
      message: '必选',
      trigger: 'change'
    },
    title: {
      required: true,
      message: '必填',
      trigger: 'blur'
    },
    ques_select: {
      required: true,
      message: '必填',
      trigger: 'blur'
    },
    score: {
      type: 'number',
      required: true,
      message: '必填',
      trigger: ['blur', 'change']
    },
    label_ids: {
      key: 'label_ids',
      required: true,
      validator: () => {
        if (selectedLabels.value.length > 0) {
          return true;
        } else {
          return new Error('请添加标签');
        }
      }
    },
    // image_url: {
    //   reqKey: 'image_url',
    //   default: '' // 图片url
    // },
  };

  const isOne = ref(false)
  // 父子组件传值 封面
  
  const imagedata = (params)=> {
    console.log("接收父组件传值",params)
    if(params.length!=0){
      isOne.value = true
      imageURL.value = []
      params.forEach(item => {
        imageURL.value.push(item)
      });
    }else{
      isOne.value = false
      imageURL.value = []
    }
  }

  const handleQuesTypeChange = type => {
    if (srcType === type) {
      return false;
    }
    switch (Number(type)) {
      case questionTypeEnum.TRUE_FALSE:
        formValue.ques_select = getDefaultTrueFalseQuesOptions();
        break;
      case questionTypeEnum.FILL_BLANK:
        formValue.ques_select = getDefaultFillBlankTypeQuesOptions();
        break;
      case questionTypeEnum.SINGLE_CHOICE:
      case questionTypeEnum.MULTIPLE_CHOICE:
        switch (Number(srcType)) {
          case questionTypeEnum.SINGLE_CHOICE:
          case questionTypeEnum.MULTIPLE_CHOICE:
            clearAnswer();
            break;
          default:
            formValue.ques_select = getDefaultOtherTypeQuesOptions();
        }
        break;
    }
    srcType = type;
  };
  const clearAnswer = () => {
    formValue.ques_select.forEach(item => {
      item.isAnswer = false;
    });
  };
  const handleSelectAnswer = index => {
    const srcIsAnswer = formValue.ques_select[index].isAnswer;
    if (srcIsAnswer) {
      formValue.ques_select[index].isAnswer = false;
    } else {
      switch (Number(formValue.type)) {
        case questionTypeEnum.SINGLE_CHOICE:
        case questionTypeEnum.TRUE_FALSE:
          formValue.ques_select.forEach(item => {
            item.isAnswer = false;
          });
          formValue.ques_select[index].isAnswer = true;
          break;
        case questionTypeEnum.MULTIPLE_CHOICE:
        case questionTypeEnum.OPEN:
          formValue.ques_select[index].isAnswer = true;
          break;
      }
    }
  };

  const handleScoreFocus = (e) => {
    setTimeout(() => {
      e.target.select();
    }, 150);
  };
  const handleScoreBlur = () => {
    let value = formValue.score;
    if (value) {
      value = Math.round(value * 10) / 10;
      value < 0.1 && (value = 0.1);
      formValue.score = value;
    } else {
      formValue.score = null;
    }
  };

  const selectedLabels = ref([]);
  const handleLabelChange = selectedLabelsArr => {
    selectedLabels.value = selectedLabelsArr;
    triggerLabelValidator();
  };
  const triggerLabelValidator = () => {
    formRef.value.validate(
      errors => {},
      rule => rule.key === 'label_ids'
    );
  };

  const itemAddLabelRef = ref(null);

  const getReqData = () => {
    const tempReqData = {};
    Object.keys(props.resReqParamsMap).forEach(key => {
      const reqKey = props.resReqParamsMap[key];
      // A.xxx B.xxx
      const quesOptions = formValue.ques_select.map((item, index) => `${ String.fromCharCode(65 + index) }.${ item.content }`);
      const quesAnswers = formValue.ques_select
        .map((item, index) => ({ seq: String.fromCharCode(65 + index), isAnswer: item.isAnswer }))
        .filter(item => item.isAnswer)
        .map(item => item.seq);
      const type = Number(formValue.type);
      switch (key) {
        case 'id':
          const tempId = props.exerciseId || formValue[key];
          if (tempId) {
            tempReqData[reqKey] = tempId;
          }
          break;
        case 'ques_select':
          switch (type) {
            case questionTypeEnum.OPEN:
            case questionTypeEnum.FILL_BLANK:
              break;
            default:
              tempReqData[reqKey] = quesOptions;  
          }
          break;
        case 'ques_answer':
          switch (type) {
            case questionTypeEnum.OPEN:
              break;
            case questionTypeEnum.FILL_BLANK:
              tempReqData[reqKey] = formValue.ques_select.map(item => item.content);
              break;
            default:
              tempReqData[reqKey] = quesAnswers;
          }
          break;
        case 'label_ids':
          tempReqData[reqKey] = selectedLabels.value.map(item => item.id);
          break;
        case 'ques_select_num':
          switch (type) {
            case questionTypeEnum.OPEN:
            case questionTypeEnum.FILL_BLANK:
              tempReqData[reqKey] = 0;
              break;
            default:
              tempReqData[reqKey] = formValue.ques_select.length;
          }
          break;
        default:
          tempReqData[reqKey] = formValue[key];
      }
    });
    tempReqData['Exercises[type_status]'] = 1;
    let SrcList = [];
    if (props.useFor === 'forData') {
      SrcList = [...imageURL.value];
    } else {
      imageURL.value.forEach(item=>{
        SrcList.push(item.url)
      });
    }
    tempReqData['Exercises[upload]'] = SrcList;
    return tempReqData;
  };

  const handleBack = () => {
    emit('back-click');
  };
  const isSetAnswer = (reqData) => {
    const type = Number(reqData[props.resReqParamsMap['type']]);
    if (type === questionTypeEnum.OPEN || type === questionTypeEnum.FILL_BLANK) {
      return true;
    } else {
      return reqData[props.resReqParamsMap['ques_answer']].length > 0;
    }
  };
  const handleSave = () => {
    formRef.value.validate(errors => {
      if (!errors) {
        const reqData = getReqData();
        if (isSetAnswer(reqData)) {
          emit('save-click', reqData);
        } else {
          message.error('请设置选项答案');
        }
      }
    });
  };

  const initFormValue = resData => {
    Object.keys(props.resReqParamsMap).forEach(key => {
      const value = resData[key];
      const type = Number(resData.type);
      switch (key) {
        case 'id':
          formValue[key] = value ? String(value) : '';
          break;
        /* 
        case 'ques_answer':
        */
        case 'upload_list':
          isOne.value = true
          let arr = []
          imageURL.value.splice(0);
          Array.isArray(resData.upload_list) && resData.upload_list.forEach((item,index)=>{
            var data = {
              // 前端所需
              name: item.file_name,
              reallyname: item.file_name,
              status: 'finished',
              url: item.file_url,
              is:false,
              // 后端所需
              file_url:item.file_url,
              file_name:item.file_name,
              file_size:item.file_size,
              file_type:'1',
              extension:item.extension
            }
            arr.push(data);
            imageURL.value.push(data);
            formValue[key] = arr;
          })
          break;
        case 'label_ids':
          break;
        case 'type':
          formValue[key] = String(value);
          break;
        case 'ques_select':
          if (type === questionTypeEnum.FILL_BLANK) {
            formValue[key] = resData.ques_answer.map(item => getDefaultQuesOption(item));
          } else if (type !== questionTypeEnum.OPEN) {
            const quesAnswers = resData.ques_answer;
            const quesOptions = value.map(item => {
              const matchs = item.match(/(.)\.([^]*)/);
              return getDefaultQuesOption(matchs[2], quesAnswers.includes(matchs[1]));
            });
            formValue[key] = quesOptions;
          }
          break;
        case 'ques_select_num':
        case 'score':
          formValue[key] = Number(value);
          break;
        default:
          formValue[key] = value;
      }
    });
    if (!!props.resReqParamsMap.label_ids) {
      const labels = resData.label_list.map(item => ({
        id: item.label_id,
        title: item.title,
        file_num: item.file_num
      }));
      selectedLabels.value = labels;
      itemAddLabelRef.value.setSelectedLabels(labels);
    }
  };

  defineExpose({
    initFormValue,
    resetFormValue: () => {
      imageURL.value.splice(0);
      Object.assign(formValue, getDefaultFormValue());
    }
  });
</script>
<style>
#PracticalTraining-exercises{
  width: 400px;
}
#PracticalTraining-exercises .n-upload-trigger.n-upload-trigger--image-card .n-upload-dragger{
  width: 80px !important;
  height: 80px !important;
  position: relative;
  position: relative;
  background: #FAFAFC url(../../../public/img/upload/C6173F69.png) no-repeat center center;
  background-size: 50% !important;
}
#PracticalTraining-exercises .title-up-text {
  position: absolute;
  left: 100px !important;
  color: #c2c2c2;
}
#PracticalTraining-exercises .n-upload-file.n-upload-file--info-status.n-upload-file--image-card-type+.n-upload-trigger.n-upload-trigger--image-card{
  /* display: none !important; */
}
#PracticalTraining-exercises .n-upload-file-list .n-upload-file.n-upload-file--image-card-type{
  width: 80px !important;
  height: 80px !important;
}
#PracticalTraining-exercises .n-upload-file-list .n-upload-file.n-upload-file--image-card-type + .n-upload-trigger.n-upload-trigger--image-card{
  /* display: none !important; */
}
#PracticalTraining-exercises .n-upload-file-list .n-upload-file.n-upload-file--image-card-type:nth-child(9) + .n-upload-trigger.n-upload-trigger--image-card{
  display: none !important;
}
#PracticalTraining-exercises .title-up-text{
  width: 144px !important;
}
#PracticalTraining-exercises .n-upload-file-list.n-upload-file-list--grid{
  display: flex !important;
  flex-wrap: wrap !important;
}
.n-upload-file-list .n-upload-file.n-upload-file--image-card-type .n-upload-file-info{
  overflow: hidden;
}
</style>

<style lang="less" scoped>
  .pics-box {
    &.read-only {
      :deep(.n-upload-file .n-upload-file-info__action .n-button:nth-child(2)) {
        display: none;
      }

      :deep(.n-upload-trigger) {
        display: none;
      }
    }
  }
</style>